import React from "react"
import { Link } from "gatsby"
import { GatsbyImage } from "gatsby-plugin-image"
import { getShopifyImage } from "gatsby-source-shopify"

import { formatPrice } from "../../utils/format-price"

import '../../scss/product-card.scss'

const ProductCard = ({ product, eager }) => {
    const {
        title,
        priceRangeV2,
        slug,
        images: [firstImage],
        storefrontImages,
        variants
    } = product

    const originalPrice = (variants) ? Math.min(...variants.map(item => item.compareAtPrice)) : 0;
    const save = originalPrice - priceRangeV2.minVariantPrice.amount

    const price = formatPrice(
        priceRangeV2.minVariantPrice.currencyCode,
        priceRangeV2.minVariantPrice.amount
    )

    const defaultImageHeight = 200
    const defaultImageWidth = 200
    let storefrontImageData = {}
    if (storefrontImages && storefrontImages.edges && storefrontImages.edges.length > 0) {
      const storefrontImage = storefrontImages.edges[0].node
      try {
        storefrontImageData = getShopifyImage({
          image: storefrontImage,
          layout: "fixed",
          width: defaultImageWidth,
          height: defaultImageHeight,
        })
      } catch (e) {
        console.error(e)
      }
    }
  
    const hasImage = firstImage || Object.getOwnPropertyNames(storefrontImageData || {}).length
    console.log(firstImage, 'first image')
    return (
        <Link to={slug} className="product-card" aria-label={`View ${title} product page`}>
            {hasImage ? (
                <div data-name="product-image-box">
                    {originalPrice ? (<div className="save">Save {formatPrice('USD', save)}</div>) : ''}
                    <GatsbyImage
                        alt={firstImage?.altText ?? title}
                        image={firstImage?.gatsbyImageData ?? storefrontImageData}
                        loading={eager ? "eager" : "lazy"}
                    />
                </div>
            ) : (
                <div style={{ height: defaultImageHeight, width: defaultImageWidth }} />
            )}
            <div className="title-line">
                <h2 as="h2">
                    {title}
                </h2>
                <span>{originalPrice ? (<s>{formatPrice('USD', originalPrice)}</s>) : ''} {price}</span>
            </div>
        </Link>
    )
}

export default ProductCard
